import socketio from 'socket.io-client';
import customParser from 'socket.io-msgpack-parser';
import { includes, forEach } from 'lodash';
import router from '@/router';
import { createMarketBuffer } from '@/services/helpers/market-updater';
import { isUserLoggedIn } from '@/services/api';
import routes from '@/../config/env';
import socketEvents, { socketEventTypes } from '@/services/helpers/socket-events';
import { getSuperAdminData } from './helpers/super-admin';

const { socketApi } = routes;
let sio = null;
let token = null;
let dispatch = null;
let marketBuffer = null;
let disconnectedWhileBuffering = false;
let debugMode = false;

export default {
  async connect(userToken, storeDispatch) {
    if (userToken) token = userToken;
    if (storeDispatch) dispatch = storeDispatch;
    const urlParams = new URLSearchParams(window.location.search);
    const debugModeParams = urlParams.get('debugMode');
    if (debugModeParams && debugModeParams === 'simple') {
      debugMode = true;
    }
    if (!sio) {
      const url = socketApi;
      const { isSuperAdmin, client } = getSuperAdminData();
      const socketOptions = {
        forceNew: true,
        transports: ['websocket'],
        allowUpgrades: false,
        parser: customParser,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
        auth: {
          token: token ? `Bearer ${token}` : '',
        },
      };

      if (isSuperAdmin) {
        socketOptions.auth['x-admin-for'] = client;
      }

      if (!marketBuffer) {
        marketBuffer = createMarketBuffer((incomingMarkets) => {
          switch (router.currentRoute.value.name) {
          case 'event':
            dispatch('updateEventMarkets', incomingMarkets);
            break;
          case 'home':
            dispatch('updateMarket', incomingMarkets);
            break;
          default:
          }
        });
      }

      sio = socketio(url, socketOptions);
      sio.connect();

      sio.on('connect_error', async (error) => {
        // If socket is active, let socketio handle reconnection
        if (sio.active) return;

        if (includes(error.message, 'jwt expired')) {
          console.log('Refreshing JWT token...');
          const {
            token: newToken, userRoles, username, operator,
          } = await isUserLoggedIn();
          token = newToken;
          dispatch('updateUser', {
            token, userRoles, username, operator,
          });
        }

        console.log('Manually reconnecting...');
        sio = null;
        this.connect();
      });

      sio.on('connect', () => {
        console.log('Connected!');

        if (disconnectedWhileBuffering) {
          marketBuffer.start();
          disconnectedWhileBuffering = false;
        }

        forEach(socketEvents.getEventTypes(), (type) => {
          switch (type) {
          case socketEventTypes.EVENT:
            dispatch('reloadEvent');
            break;
          case socketEventTypes.EVENT_LIST:
            dispatch('loadEvents');
            break;
          default:
          }
        });
      });

      sio.on('market', (data) => {
        if (!marketBuffer.active) return;
        marketBuffer.append(data);
        if (debugMode) {
          console.log('Market Update:', data.marketType?.marketCode, ' marketId --->', data.marketId, ' eventId ---->', data.eventId, '\n', data);
        }
      });

      sio.on('soccer', (data) => {
        dispatch('updateEvent', data);
        dispatch('updateEventDetails', data);
        if (debugMode) {
          console.log('Event State Update:', ' eventId ---->', data.eventId, ' sport --->', data.sport, '\n', data);
        }
      });

      sio.on('basketball', (data) => {
        dispatch('updateEvent', data);
        dispatch('updateEventDetails', data);
        if (debugMode) {
          console.log('Event State Update:', ' eventId ---->', data.eventId, ' sport --->', data.sport, '\n', data);
        }
      });

      sio.on('football', (data) => {
        dispatch('updateEvent', data);
        dispatch('updateEventDetails', data);
        if (debugMode) {
          console.log('Event State Update:', ' eventId ---->', data.eventId, ' sport --->', data.sport, '\n', data);
        }
      });

      sio.on('hockey', (data) => {
        dispatch('updateEvent', data);
        dispatch('updateEventDetails', data);
        if (debugMode) {
          console.log('Event State Update:', ' eventId ---->', data.eventId, ' sport --->', data.sport, '\n', data);
        }
      });

      sio.on('baseball', (data) => {
        dispatch('updateEvent', data);
        dispatch('updateEventDetails', data);
        if (debugMode) {
          console.log('Event State Update:', ' eventId ---->', data.eventId, ' sport --->', data.sport, '\n', data);
        }
      });

      sio.on('ultimate', (data) => {
        dispatch('updateEvent', data);
        dispatch('updateEventDetails', data);
        if (debugMode) {
          console.log('Event State Update:', ' eventId ---->', data.eventId, ' sport --->', data.sport, '\n', data);
        }
      });

      sio.on('event-info', (data) => {
        if (debugMode) console.log('Event Info Update:', ' eventId ---->', data.eventId, '\n', data);

        if (data.messageType === 'event-primary-feeds-updated') {
          dispatch('processEventFeedChange', data);
          return;
        }

        dispatch('processEventInfo', data);
      });

      sio.on('sgp', () => {
        dispatch('updateBetslipOnSocketUpdate');
      });

      sio.on('event-flags', (data) => {
        if (data.messageType === 'event-disabled') {
          dispatch('processEventDisabled', data);
        } else {
          dispatch('processEventFlagsInfo', data);
        }
        if (debugMode) {
          console.log('Event Info Update:', ' eventId ---->', data.eventId, '\n', data);
        }
      });

      sio.on('disconnect', (reason) => {
        console.log('Disconnected!, Reason ---->  ', reason);

        if (marketBuffer?.active) {
          marketBuffer.stop();
          disconnectedWhileBuffering = true;
        }

        sio.disconnect();
        sio = null;
        if (reason !== 'io client disconnect') { // dont reconnect on manual disconnect
          console.log('Reconnecting...');
          this.connect();
        }
      });
    }
  },
  disconnect() {
    if (!sio) return;

    socketEvents.clearAll();

    sio.disconnect();
    sio = null;
  },
  get connected() {
    return !!sio;
  },

  subscribeEvent({ id, source }) {
    if (!sio) return;

    socketEvents.register(
      socketEventTypes.EVENT,
      {
        eventIds: [id],
        sources: [source],
      },
      () => {
        marketBuffer.start();

        sio.emit('subscribe', {
          subscribeFor: 'EVENTS_INFO',
          eventIds: [id],
        });
        sio.emit('subscribe', {
          eventIds: [id],
          subscribeFor: 'EVENTS_BY_SOURCE',
          sources: [source],
        });
        sio.emit('subscribe', {
          eventIds: [id],
          subscribeFor: 'EXT_MARKETS_BY_SOURCE',
          sources: [
            'DRAFTKINGS', 'FANDUEL', 'BETMGM_NEWJERSEY', 'FIVE_DIMES', 'BET_365',
            'RUSHBET', 'PINNACLE', 'BWIN', 'LADBROKES', 'WILLIAM_HILL',
            'MARATHON', 'TEN_BET', 'BET_188', 'BETVICTOR', 'FONBET', 'SBO', 'BETFAIR', 'ISN',
          ],
        });
        sio.emit('subscribe', {
          eventIds: [id],
          subscribeFor: 'MARKETS',
        });
      },
    );
  },
  unsubscribeEvent({ id, source }) {
    if (!sio) return;

    socketEvents.clear(socketEventTypes.EVENT, () => {
      marketBuffer.stop();

      sio.emit('unsubscribe', {
        subscribeFor: 'EVENTS_INFO',
        eventIds: [id],
      });
      sio.emit('unsubscribe', {
        eventIds: [id],
        subscribeFor: 'EVENTS_BY_SOURCE',
        sources: [source],
      });
      sio.emit('unsubscribe', {
        eventIds: [id],
        subscribeFor: 'EXT_MARKETS_BY_SOURCE',
        sources: [
          'DRAFTKINGS', 'FANDUEL', 'BETMGM_NEWJERSEY', 'FIVE_DIMES', 'BET_365',
          'RUSHBET', 'PINNACLE', 'BWIN', 'LADBROKES', 'WILLIAM_HILL',
          'MARATHON', 'TEN_BET', 'BET_188', 'BETVICTOR', 'FONBET', 'SBO', 'BETFAIR', 'ISN',
        ],
      });
      sio.emit('unsubscribe', {
        eventIds: [id],
        subscribeFor: 'MARKETS',
      });
    });
  },

  subscribeEventList(groupedEventsBySource) {
    if (!sio) return;

    forEach(groupedEventsBySource, (ids, source) => {
      socketEvents.register(
        socketEventTypes.EVENT_LIST,
        {
          eventIds: ids,
          sources: [source],
        },
        () => {
          sio.emit('subscribe', {
            subscribeFor: 'EVENTS_INFO',
            eventIds: ids,
          });
          sio.emit('subscribe', {
            subscribeFor: 'EVENTS_BY_SOURCE',
            eventIds: ids,
            sources: [source],
          });
          sio.emit('subscribe', {
            subscribeFor: 'MARKETS_BY_CODE',
            eventIds: ids,
            marketCodes: ['RESULT', 'RESULT_EXC_OT', 'GOAL_OVER_UNDER', 'POINT_HANDICAP', 'RUN_HANDICAP', 'GOAL_HANDICAP', 'POINT_OVER_UNDER', 'RUN_OVER_UNDER'],
          });
        },
      );
    });

    marketBuffer.start();
  },
  unsubscribeEventList(groupedEventsBySource) {
    if (!sio) return;

    forEach(groupedEventsBySource, (ids, source) => {
      socketEvents.unregister(
        socketEventTypes.EVENT_LIST,
        {
          eventIds: ids,
          sources: [source],
        },
        () => {
          sio.emit('unsubscribe', {
            subscribeFor: 'EVENTS_INFO',
            eventIds: ids,
          });
          sio.emit('unsubscribe', {
            eventIds: ids,
            subscribeFor: 'EVENTS_BY_SOURCE',
            sources: [source],
          });
          sio.emit('unsubscribe', {
            eventIds: ids,
            subscribeFor: 'MARKETS_BY_CODE',
            marketCodes: ['RESULT', 'RESULT_EXC_OT', 'GOAL_OVER_UNDER', 'POINT_HANDICAP', 'RUN_HANDICAP', 'GOAL_HANDICAP', 'POINT_OVER_UNDER', 'RUN_OVER_UNDER'],
          });
        },
      );
    });

    marketBuffer.stop();
  },
  subscribeToSgpChanges(eventId) {
    if (!sio) return;

    sio.emit('subscribe', {
      subscribeFor: 'SGP',
      eventIds: [eventId],
    });
  },
  unsubscribeToSgpChanges(eventId) {
    if (!sio) return;

    sio.emit('unsubscribe', {
      subscribeFor: 'SGP',
      eventIds: [eventId],
    });
  },
};
